import React, { useEffect, useState } from "react";
import Image from "../../../common/atoms/Image";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import LabelField from "../../../common/atoms/LabelField";
import ModalPopup from "../../../common/atoms/ModalPopup";
import ToggleButton from "../../../common/atoms/ToggleButton";
import Header from "../../../common/layout/Header";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import Button from "../../../common/atoms/Button";
import { useSelector } from "react-redux";
import ProfilePictureUpload from "../../../Dashboard/EmployerRegistration/ProfilePictureUpload";
import { REGISTER_COUNTRY_LIST, UPDATE_EMPLOYEE } from "../../../../routes/ApiEndpoints";
import { ApiCall } from "../../../../services/ApiServices";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { initialState } from "../MyAccount/State";
import { MyAccount } from "../MyAccount/MyAccountInterface";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import { DropdownOptions } from "../../../Dashboard/services/CompanyInterface";
import { formatUserData, formValidation } from "../MyAccount/services/MyaccountUtils";
import Calender from "../../../common/molecules/Calender";
import { Option } from "react-multi-select-component";
import { selectLanguages } from "../../../../features/settings/settingSlice";
import SignatureField from "../../../common/atoms/SignatureField";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import AccessControl from "../../../../services/RolesAndPermissions/AccessControl";

const ProfilePage = () => {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: any) => state.auth);
  const [formState, setFormState] = useState<MyAccount>(initialState);
  const [optionsData, setOptionsData] = useState<any>(useSelector(selectLanguages));
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    setFormState((prevState) => {
      const updatedFormValues = { ...prevState.formValues };
      let current: any = updatedFormValues;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = { ...current[key] };
          current = current[key];
        }
      });

      return {
        ...prevState,
        formValues: updatedFormValues,
      };
    });
  };
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const inputFieldProps = {
    labelWrapperClassName: "col-4 align-self-center pe-0",
    colClassName: "col-8",
    className: "inputFieldColor",
  };
  const handleShowPasswordToggle = () => {
    setFormState((prevState: any) => ({
      ...prevState,
      showPassword: !prevState?.showPassword,
    }));
  };

  const fetchUser = async (userid: number) => {
    try {
      const url = `${UPDATE_EMPLOYEE}/${userid}`;
      const response = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);

      if (response && response?.status === 'success') {
        const formattedData = formatUserData(response?.data);

        setFormState((prevState: any) => ({
          ...prevState,
          formValues: formattedData,
        }));
      } else {
        console.error("Unexpected response status:", response?.status);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  const fetchCountryOptions = async () => {
    const url = REGISTER_COUNTRY_LIST;
    try {
      const result = await ApiCall?.getService(url, "GET");
      setFormState((prev) => ({
        ...prev,
        countries: result?.data || [],
      }));
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleDateFormat = (dateValue: Date | null, field: string) => {
    // Format the date to "YYYY-MM-DD"
    const formattedDate = dateValue
      ? new Date(
        new Date(dateValue).getTime() -
        new Date(dateValue).getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      : null;

    const fieldParts = field.split(".");

    const updateState = (state: any, value: any) => {
      let currentState = state;
      fieldParts.forEach((part, index) => {
        if (index === fieldParts.length - 1) {
          currentState[part] = value;
        } else {
          currentState[part] = currentState[part] || {};
          currentState = currentState[part];
        }
      });
      return state;
    };
    setFormState((prevState) => ({
      ...prevState,
      formValues: updateState({ ...prevState.formValues }, formattedDate),
    }));
  };

  useEffect(() => {
    fetchUser(user?.userId);
    fetchCountryOptions();
  }, [user?.userId]);

  const handleShowConfirmPasswordToggle = () => {
    setFormState((prevState: any) => ({
      ...prevState,
      showConfirmPassword: !prevState?.showConfirmPassword,
    }));
  };
  const cardTitleProps = {
    className: "myAccountCardTitle colorPrimary mb-3",
  };

  const handleSelectChange = (
    selectedOption: DropdownOptions | DropdownOptions[] | null,
    fieldPath: string,
    isMulti = false
  ) => {
    const value = isMulti
      ? (selectedOption as DropdownOptions[])?.map((option) => option.value) || []
      : selectedOption ? (selectedOption as DropdownOptions).value : null;

    setFormState((prev: MyAccount) => {
      const updatedFormValues = { ...prev.formValues };
      setNestedValue(updatedFormValues, fieldPath, value);

      return {
        ...prev,
        formValues: updatedFormValues,
      };
    });
  };

  const handleToggle = (field: string, value: boolean) => {
    setFormState((prevState: any) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        privacy: {
          ...prevState.formValues.privacy,
          [field]: value,
        }
      }
    }));
  };

  // Helper function to update nested values
  const setNestedValue = (obj: Record<string, any>, path: string, value: any) => {
    const keys = path.split(".");
    let current = obj;

    keys.slice(0, -1).forEach((key) => {
      if (!current[key]) current[key] = {};
      current = current[key];
    });

    current[keys[keys.length - 1]] = value;
  };

  const handleSubmit = async () => {
    const errors = formValidation(
      formState,
    );
    if (Object.keys(errors).length > 0) {
      setFormState((prev: MyAccount) => ({
        ...prev,
        errors,
      }));
      return;
    }
    try {
      let response;
      if (user?.token) {
        response =
          await ApiCall?.service(
            `${UPDATE_EMPLOYEE}/${user?.userId}`,
            "PUT",
            formState?.formValues,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          )
      }
      if (response.status === 'success') {
        CustomNotify({ type: "success", message: response?.message });
      } else if (response.status === 'validation-error') {
        const errorMessages = response?.data;
        const formattedErrorMessages = Object.values(errorMessages)
          .flat()
          .join("\n");
        CustomNotify({
          type: "error",
          message: formattedErrorMessages,
        });
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
    catch (error: unknown) {
      console.error(error);
    }
  };

  const clearFieldError = (fieldPath: string) => {
    setFormState((prevState: MyAccount) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldPath]: null,
      },
    }));
  };

  const handleSaveSignature = (dataUrl: any) => {
    setFormState((prevState: MyAccount) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        signature: dataUrl,
      },
    }));
    handleCloseModal();
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "my_account",
            read: true,
            update: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Header
          headerName={t("My Account")}
          headerClassName="myAccountCardTitle"
        />
        <div className="row equal-cols myProfilePageWrapper scrollBar justify-content-center">
          <div className="col-6">
            <div className="contentBg mb-3">
              <ProfilePictureUpload
                t={(key) => key}
                user={user}
                title={t("Manage my account")}
                formState={formState}
                setFormState={setFormState}
                fileType="1"
              />
              <div className="row mb-3" onClick={() => clearFieldError("function")}>
                <LabelWithInputField
                  label={t("Function") + ":"}
                  type="text"
                  value={formState?.formValues?.function}
                  name="function"
                  handleChange={handleChange}
                  id="function"
                  error={formState?.errors?.function}
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("first_name")}>
                <LabelWithInputField
                  label={t("First name") + ":"}
                  type="text"
                  value={formState?.formValues?.first_name}
                  name="first_name"
                  handleChange={handleChange}
                  id="first_name"
                  error={formState?.errors?.first_name}
                  isMandatory
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("last_name")}>
                <LabelWithInputField
                  label={t("Last name") + ":"}
                  type="text"
                  value={formState?.formValues?.last_name}
                  name="last_name"
                  handleChange={handleChange}
                  id="last_name"
                  error={formState?.errors?.last_name}
                  isMandatory
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("email")}>
                <LabelWithInputField
                  label={t("Email") + ":"}
                  type="email"
                  value={formState?.formValues?.email}
                  name="email"
                  handleChange={handleChange}
                  id="email"
                  error={formState?.errors?.email}
                  isMandatory
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-2" onClick={() => clearFieldError("date_of_birth")}>
                <div className="col-4 align-self-center">
                  <LabelField title={t("Date of birth") + ":"} isMandatory={true} />
                </div>
                <div className="col-8 position-relative calendarSectionWrapper">
                  <Calender
                    onChange={(date) =>
                      date && handleDateFormat(date, "date_of_birth")
                    }
                    selectedDate={
                      formState?.formValues?.date_of_birth ? new Date(formState?.formValues?.date_of_birth) : null
                    }
                    maxDate={new Date()}
                    error={formState?.errors?.date_of_birth}
                    name="date_of_birth"
                  />
                </div>
              </div>
              <div className="row mb-3 multiSelectWrapper" onClick={() => clearFieldError("language_id")}>
                <SelectWithSearch
                  fieldLabel={t("Language") + ":"}
                  title={t("Language")}
                  name="language_id"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={optionsData}
                  value={
                    optionsData?.find(
                      (option: any) => option?.value === formState?.formValues?.language_id
                    ) || null
                  }
                  onChange={(selectedOption: Option) =>
                    handleSelectChange(selectedOption, "language_id")
                  }
                  isMulti={false}
                  className="select-field"
                  error={formState?.errors?.language_id}
                  isTranslate={true}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("address.residence.street")}>
                <LabelWithInputField
                  label={t("Street") + ":"}
                  type="text"
                  value={formState?.formValues?.address?.residence?.street}
                  name="address.residence.street"
                  handleChange={handleChange}
                  id="street"
                  isMandatory
                  error={formState?.errors?.[`address_residence_street`]}
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("address.residence.number")}>
                <LabelWithInputField
                  label={t("House no") + ":"}
                  type="text"
                  value={formState?.formValues?.address?.residence?.number}
                  name="address.residence.number"
                  handleChange={handleChange}
                  id="number"
                  isMandatory
                  error={formState?.errors?.[`address_residence_number`]}
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("address.residence.postal_code")}>
                <LabelWithInputField
                  label={t("Postal code") + ":"}
                  type="text"
                  value={formState?.formValues?.address?.residence?.postal_code}
                  name="address.residence.postal_code"
                  handleChange={handleChange}
                  id="postalcode"
                  isMandatory
                  error={formState?.errors?.[`address_residence_postal_code`]}
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3 multiSelectWrapper" onClick={() => clearFieldError("address.residence.country")}>
                <SelectWithSearch
                  fieldLabel={`${t("Country")}: `}
                  title={`${t("Country")}:`}
                  name="address.residence.country"
                  placeHolder={t("Select")}
                  search
                  options={formState.countries || []}
                  value={formState?.countries?.find(
                    (option: DropdownOptions) => option.value === formState?.formValues?.address?.residence?.country
                  ) || null}
                  onChange={(selectedOptions) =>
                    handleSelectChange(selectedOptions, "address.residence.country", false)
                  }
                  isMulti={false}
                  className="select-field"
                  isTranslate
                  isMandatory
                  error={formState?.errors?.[`address_residence_country`]}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("phone_number")}>
                <LabelWithInputField
                  label={t("Mobile no") + ":"}
                  type="text"
                  value={formState?.formValues?.phone_number}
                  name="phone_number"
                  handleChange={handleChange}
                  id="mobileno"
                  error={formState?.errors?.phone_number}
                  isMandatory
                  {...inputFieldProps}
                />
              </div>
            </div>
            <div className="contentBg">
              <LabelField title={t("Manage signature")} {...cardTitleProps} />
              <div className="row">
                <div className="col-12 text-center my-3">
                  {formState?.formValues?.signature && <Image src={formState?.formValues?.signature} imageWidth="18vw" imageHeight="4vw" />}
                </div>
                <div className="text-end" onClick={handleOpenModal}>
                  <LabelField
                    title={t("Edit")}
                    className="cursor-pointer colorPrimary poppins-semibold"
                  />
                </div>
              </div>
              <ModalPopup show={showModal} handleClose={handleCloseModal}>
                <div className="text-center">
                  <SignatureField
                    onSave={handleSaveSignature}
                    dataUrl={formState?.formValues?.signature}
                    width="100%"
                    height="300px"
                    saveBtnClass="btn rounded-pill p-0"
                    clearBtnClass="btn rounded-pill p-0"
                  />
                </div>
              </ModalPopup>
            </div>
          </div>
          <div className="col-6">
            <div className="contentBg">
              <LabelField title={t("Security")} {...cardTitleProps} />
              <div className="row mb-3" onClick={() => clearFieldError("security.password")}>
                <LabelWithInputField
                  label={t("Change password") + ":"}
                  type={formState?.showPassword ? "text" : "password"}
                  value={formState?.formValues?.security?.password}
                  name="security.password"
                  handleChange={handleChange}
                  id="changePass"
                  isIcon={true}
                  width={"1vw"}
                  height={"1vw"}
                  error={formState?.errors?.password}
                  inputClassWrapper="position-relative"
                  iconName={formState?.showPassword ? "eyeSolid" : "eyeSlashSolid"}
                  handleIcon={handleShowPasswordToggle}
                  autoComplete="off"
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-5" onClick={() => clearFieldError("security.confirm_password")}>
                <LabelWithInputField
                  label={t("Repeat password") + ":"}
                  type={formState?.showConfirmPassword ? "text" : "password"}
                  value={formState?.formValues?.security?.confirm_password}
                  name="security.confirm_password"
                  handleChange={handleChange}
                  id="repeatPass"
                  isIcon={true}
                  width={"1vw"}
                  height={"1vw"}
                  error={formState?.errors?.confirm_password}
                  inputClassWrapper="position-relative"
                  iconName={formState?.showConfirmPassword ? "eyeSolid" : "eyeSlashSolid"}
                  handleIcon={handleShowConfirmPasswordToggle}
                  autoComplete="off"
                  {...inputFieldProps}
                />
              </div>
              <LabelField title={t("Communication")} {...cardTitleProps} />
              <div className="row mt-4 mb-3" onClick={() => clearFieldError("communication.contracts_email")}>
                <LabelWithInputField
                  label={t("Email contracts") + ":"}
                  type="text"
                  value={formState?.formValues?.communication?.contracts_email}
                  name="communication.contracts_email"
                  error={formState?.errors?.contracts_email}
                  handleChange={handleChange}
                  id="contracts_email"
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("communication.invoice_email")}>
                <LabelWithInputField
                  label={t("Email invoicing") + ":"}
                  type="text"
                  value={formState?.formValues?.communication?.invoice_email}
                  name="communication.invoice_email"
                  error={formState?.errors?.invoice_email}
                  handleChange={handleChange}
                  id="email_invoicing"
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-3" onClick={() => clearFieldError("communication.planning_contact_person")}>
                <LabelWithInputField
                  label={t("Planning contact person") + ":"}
                  type="text"
                  value={formState?.formValues?.communication?.planning_contact_person}
                  name="communication.planning_contact_person"
                  error={formState?.errors?.planning_contact_person}
                  handleChange={handleChange}
                  id="planning_contact_person"
                  {...inputFieldProps}
                />
              </div>
              <div className="row mb-5" onClick={() => clearFieldError("communication.planning_contact_number")}>
                <LabelWithInputField
                  label={t("Planning contact phone number") + ":"}
                  type="text"
                  value={formState?.formValues?.communication?.planning_contact_number}
                  name="communication.planning_contact_number"
                  error={formState?.errors?.planning_contact_number}
                  handleChange={handleChange}
                  id="planning_contact_phone_number"
                  {...inputFieldProps}
                />
              </div>
              <LabelField title={t("Privacy")} {...cardTitleProps} />
              <div className="row mt-4 mb-3">
                <div className="col-7">
                  <LabelField
                    title={t("Commmunication via mail") + ":"}
                    className="colorPrimary poppins-medium"
                  />
                </div>
                <div className="col-4 align-self-center">
                  <ToggleButton value={formState?.formValues?.privacy?.via_email} onToggle={(value: boolean) => handleToggle("via_email", value)}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-7">
                  <LabelField
                    title={t("Commmunication via phone") + ":"}
                    className="colorPrimary poppins-medium"
                  />
                </div>
                <div className="col-4 align-self-center">
                  <ToggleButton value={formState?.formValues?.privacy?.via_phone} onToggle={(value: boolean) => handleToggle("via_phone", value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3 privacyText">
                    {t(
                      "AbsoluteYOU! collects, stores and protects your personal data in order to offer you a better and more personalised service"
                    ) + ":"}
                  </div>
                  <div className="mb-3 privacyText">
                    {t(
                      "You can decide at any time how our office may contact you to, for example, deliver your documents, ask your opinion as part of a survey or contact you regarding your file"
                    ) + ":"}
                  </div>
                  <div className="privacyText">
                    {t(
                      "Simply select the means of communication you prefer above"
                    ) + "."}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 my-3">
            <div>
              <Button
                type="submit"
                className="searchBtn px-3 float-end"
                title={t("Save")}
                style={{ width: "fit-content" }}
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ProfilePage);
