import React, { useState } from "react";
import { t } from "../common/atoms/Constants";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import RadioField from "../common/atoms/RadioField";
import LabelField from "../common/atoms/LabelField";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import Navigation from "./Navigation";
import { useSelector, useDispatch } from "react-redux";
import { selectBrowsers } from "../../features/settings/settingSlice";
import { Formdata } from "./Interfaces";
import { validateSection } from "./Service";

interface Option {
  value?: number | string | null;
  label?: string;
}

interface CompanyInformationProps {
  agreementData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option, section: keyof Formdata) => void;
  section: keyof Formdata;
  errors: any;
  updateErrors: any;
  handleSelectChange: (selectedOption: Option, fieldPath: string, section: keyof Formdata) => void;
  countries?: Array<Option> | any;
}

  // Helper function to get nested values
  export const getNestedValue = (obj: any, path: string, fallback: any = "") => {
    const keys = path.split('.');
    let value = obj;
    for (let key of keys) {
      if (value && value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return fallback;
      }
    }
    return value;
  };

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  agreementData,
  handleChange,
  errors,
  updateErrors,
  handleSelectChange,
  section,
  countries,
}) => {
  const [isLastTab, setIsLastTab] = useState(false);
  const dispatch = useDispatch();



  // Define input fields for the company information
  const inputFields = [
    { label: "VAT number", name: "vat_number", id: "vatNumber", isMandatory: false },
    { label: "Official legal company name", name: "official_company_name", id: "legalName", isMandatory: true },
    { label: "Company call name", name: "company_name", id: "companyName", isMandatory: true },
    { label: "Company client number", name: "client_number", id: "clientNumber", isMandatory: true },
    { label: "RSZ-number", name: "company_rsz_number", id: "rszno", isMandatory: false },
    { label: "Legal address: Street", name: "legal_address.street", id: "streetName", isMandatory: true },
    { label: "Legal address: House number", name: "legal_address.number", id: "houseNo", isMandatory: true },
    { label: "Legal address: Box number", name: "legal_address.box", id: "boxNo", isMandatory: false },
    { label: "Legal address: Postal code", name: "legal_address.postal_code", id: "postalCode", isMandatory: true },
    { label: "Legal address: City", name: "legal_address.city", id: "city", isMandatory: true },
    { label: "Legal address: Phone number", name: "phone_number", id: "phoneNo", isMandatory: true },
    { label: "Legal address: Fax number", name: "fax_number", id: "faxNo", isMandatory: false },
    { label: "Legal address: Email", name: "legal_entity_mail", id: "email", isMandatory: true },
    { label: "Website", name: "website", id: "website", isMandatory: false },
  ];

  const nonMandatoryFields = [
    "vat_number",
    "company_rsz_number",
    "legal_address.box",
    "fax_number",
    "website",
  ];

  const countryOptions = countries?.countries || [];
  const companyOptions: Option[] = [{ value: 1, label: "Klant" }];
  const browserOptions: Option[] = useSelector(selectBrowsers);

  // Handle validation on next click
  const handleNext = () => {
    const errors = validateSection(section, agreementData, inputFields, nonMandatoryFields);
    updateErrors(errors);
    if (Object.keys(errors).length > 0) {
      console.log("Next clicked");
    } else {
      console.log("Errors are there" + errors);
    }
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };

  return (
    <>
      <div className="row">
        {/* Render the input fields for company information */}
        {inputFields.map((field) => (
          <div key={field.id} className="col-6 mb-3">
            <LabelWithInputField
              type={field.id === "email" ? "email" : "text"}
              label={t(field.label)}
              value={getNestedValue(agreementData, field.name) || ""}
              name={field.name}
              id={field.id}
              handleChange={(e) => handleChange(e, section)}
              error={getNestedValue(errors?.[section], field.name) || ""}
              className="ps-2"
              isMandatory={field?.isMandatory}
            />
          </div>
        ))}

        {/* Type of Company Select */}
        <div className="col-6 multiSelectWrapper mb-3">
          <SelectWithSearch
            fieldLabel={`${t("Type of company")}:`}
            title={t("Type of company")}
            name="company_type"
            placeHolder={t("Select")}
            search
            isMandatory
            options={companyOptions || []}
            value={companyOptions?.find(
              (option: Option) => option.value === agreementData?.company_type
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "company_type", section);
            }}
            isMulti={false}
            className="select-field"
            error={getNestedValue(errors?.[section], "company_type") || ""}  // Fix error prop
            isSelectOption={false}
            isTranslate
          />
        </div>

        {/* Country Select */}
        <div className="col-6 multiSelectWrapper mb-3">
          <SelectWithSearch
            fieldLabel={`${t("Legal address: Country")}:`}
            title={t("Legal address: Country")}
            name="legal_address.country"
            placeHolder={t("Select")}
            search
            isMandatory
            options={countryOptions || []}
            value={countryOptions?.find(
              (option: Option) => option.value === agreementData?.legal_address?.country
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "legal_address.country", section);
            }}
            isMulti={false}
            className="select-field"
            error={getNestedValue(errors?.[section], "legal_address.country") || ""}  // Fix error prop
            isTranslate
          />
        </div>

        {/* Browsers */}
        <div className="col-6 mb-3">
          <SelectWithSearch
            fieldLabel={`${t("Browsers")}:`}
            title={t("Browsers")}
            name="browsers"
            placeHolder={t("Select")}
            search
            isMandatory
            options={browserOptions || []}
            value={browserOptions?.find(
              (option: Option) => option.value === agreementData?.browsers
            ) || 2}
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "browsers", section);
            }}
            isSelectOption={false}
            isMulti={false}
            className="select-field"
            error={getNestedValue(errors?.[section], "browsers") || ""}  // Fix error prop
            isTranslate
          />
        </div>

        {/* Language Radio Buttons */}
        <div className="col-6">
          <div className="mb-2">
            <LabelField title={t("Language")} isMandatory />
          </div>

          <RadioField
            name="company_language"
            label={t("Dutch")}
            className="radioBtn"
            ischecked={Number(agreementData?.company_language) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="langDutch"
            value={1}
            containerClassName="companyLanguage me-4"
          />

          <RadioField
            name="company_language"
            label={t("French")}
            className="radioBtn"
            ischecked={Number(agreementData?.company_language) === 2}
            handleChange={(e) => handleChange(e, section)}
            id="langFrench"
            value={2}
            containerClassName="companyLanguage"
          />
        </div>

        {/* Labour Regulations Radio Buttons */}
        <div className="col-6">
          <div className="mb-2">
            <LabelField title={t("Labour regulations (arbeidsreglement)")} isMandatory />
          </div>
          <RadioField
            name="labour_regulation"
            label={t("Yes")}
            className="radioBtn"
            ischecked={Number(agreementData?.labour_regulation) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="yesLabourRegulations"
            value={1}
            containerClassName="companyLanguage me-4"
          />

          <RadioField
            name="labour_regulation"
            label={t("No")}
            className="radioBtn"
            ischecked={Number(agreementData?.labour_regulation) === 2}
            handleChange={(e) => handleChange(e, section)}
            id="noLabourRegulations"
            value={0}
            containerClassName="companyLanguage"
          />
        </div>
      </div>

      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default CompanyInformation;
