import React, { useEffect, useState } from "react";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import IconWithTitle from "../common/molecules/IconWithTitle";
import "./css/welcomeDashboard.css";
import imageOne from "../../static/images/DummyImage.jpg";
import imageTwo from "../../static/images/DummyImage1.png";
import Button from "../common/atoms/Button";
import { useNavigate } from "react-router-dom";
import AddCompanyModal from "./AddCompany";
import LinkCustom from "../common/atoms/Link";
import { useSelector } from "react-redux";
import {
  GET_COUNTRIES,
  GET_EMPLOYER,
  PC_LIST,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { useDispatch } from "react-redux";
import { addUserDetails } from "../../features/auth/AuthSlice";
import { LOCATION_DASHBOARD } from "../../routes/RouteConstants";
import defaultCompanyIcon from "../../static/images/emptyCompany.png";
import Header from "../common/layout/Header";
import { Company, DropdownOptions, ReduxState } from "./services/CompanyInterface";
import { selectIbanCountries, selectTimezones } from "../../features/settings/settingSlice";
import LabelField from "../common/atoms/LabelField";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

type OptionType = { label: string; value: string };

export const icons = [
  {
    src: imageOne,
    title: t("De sandwich"),
    value: 116,
  },
  {
    src: imageTwo,
    title: t("Agreement"),
    value: 117,
  },
  {
    src: imageTwo,
    title: t("Company"),
    value: 118,
  },
];

const WelcomeDashboard = () => {
  const iconWidth = useResponsiveValue("5vw", "3vw", "1vw");
  const addCompanyIcon = useResponsiveValue("15vw", "10vw", "7vw");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
  const user = useSelector((state: ReduxState) => state?.auth);
  const iban_countries = useSelector(selectIbanCountries);
  const timezones = useSelector(selectTimezones);
  const [companyData, setCompanyData] = useState<{
    companyid: number | null;
    companies: Company[];
    countries: DropdownOptions[] | undefined;
    iban: DropdownOptions[] | undefined;
    pc: DropdownOptions[] | undefined;
    timezones: DropdownOptions[] | undefined;
  }>({
    companies: [],
    countries: [],
    iban: [],
    pc: [],
    companyid: null,
    timezones: [],
  });


  useEffect(() => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
    }));
  }, [user?.companies]);

  const handleShowModal = async (company: Company | null) => {
    setShowModal(true);
    setCompanyData((prev) => ({
      ...prev,
      companyid: company ? company?.id : null,
    }));
    await Promise.all([
      fetchCountryOptions(),
      fetchPcOptions(),
      fetchIbanCountries(),
    ]);
  };

  const handleCloseModal = () => setShowModal(false);
  const navigator = useNavigate();

  const handlePcChange = (selectedOption: OptionType[] | null) => {
    setPcValue(selectedOption);
  };

  const handleCompanyClick = (company: Company | null) => {
    dispatch(
      addUserDetails({
        currentCompany: {
          id: company?.id,
          name: company?.name,
          profile_picture_url:
            company?.profile_picture_url ?? defaultCompanyIcon,
          locations: company?.locations,
        },
      })
    );
    navigator(LOCATION_DASHBOARD);
  };

  const fetchCountryOptions = async () => {
    const url = REGISTER_COUNTRY_LIST;
    try {
      const result = await ApiCall?.getService(url, "GET");
      setCompanyData((prev) => ({
        ...prev,
        countries: result?.data || [],
      }));
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const fetchPcOptions = async () => {
    try {
      const result = await ApiCall?.getService(PC_LIST, "GET");
      const pcData = result?.data?.map((pc: DropdownOptions) => ({
        value: pc?.value,
        label: pc?.label,
      }));
      setCompanyData((prev) => ({
        ...prev,
        pc: pcData,
      }));
    } catch (error) {
      console.error("Error fetching PC data:", error);
    }
  };

  const fetchIbanCountries = async () => {
    try {
      const ibanData = iban_countries?.map((iban: DropdownOptions) => ({
        value: iban?.value,
        label: iban?.label,
      }));
      const timezoneData = timezones?.map((timezone: DropdownOptions) => ({
        value: timezone?.value,
        label: timezone?.label,
      }));
      setCompanyData((prev) => ({
        ...prev,
        iban: ibanData,
        timezones: timezoneData,
      }));
    } catch (error) {
      console.error("Error fetching IBAN countries:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Welcome") + "!"}
        headerClassName="myAccountCardTitle"
      />
      <div className="container-fluid">
        <div className="welcomeDashboardWrapper">
          <div className="bg-white rounded-5">
            <div className="row">
              <div className="col-12 align-self-center text-center d-block d-lg-none mb-4">
                <LabelField
                  title={t("Welcome") + "!"}
                  className="myAccountCardTitle mb-0"
                />
              </div>
              <div className="col-12 text-center welcomeText colorPrimary mobileFont">
                {t("Click on a company to start")}
              </div>
              <div className="col-12 companyWrapper scrollBar">
                <div className="row justify-content-center companyWrapperGrid">
                  {companyData?.companies?.map((company, index) => (
                    <div
                      key={company?.id}
                      className="col-lg-2 col-md-4 equal-cols flex-column mb-3"
                    >
                      <IconWithTitle
                        isImage={true}
                        isTitle={true}
                        isSubIcon={true}
                        isQrCode={true}
                        isManageLocation={true}
                        iconId={company?.id}
                        src={company?.profile_picture_url ?? defaultCompanyIcon}
                        imageBorderRadius="50%"
                        imageWidth={addCompanyIcon}
                        imageHeight={addCompanyIcon}
                        imageWrapperClassName="text-center text-break addCompanyImageDashboard cursor-pointer"
                        subIconClassName="cursor-pointer"
                        labelClassName="colorPrimary companyName text-break cursor-pointer mobileFont"
                        titleImageWrapperClassName="text-center flex-grow-1"
                        title={company?.name}
                        subIconWidth={iconWidth}
                        subIconHeight={iconWidth}
                        subIconImageWrapperClassName="text-center mt-2"
                        subIconname="Settings"
                        handleCompanyClick={() => {
                          handleCompanyClick(company);
                        }}
                        handleShowModal={() => {
                          handleShowModal(company);
                        }}

                      />
                    </div>
                  ))}
                  <div className="addCompany text-center col-2">
                    <Button
                      handleClick={() => {
                        handleShowModal(null);
                      }}
                      className="bg-transparent border-0 cursor-pointer"
                    >
                      <IconWithTitle
                        isIcon={true}
                        isTitle={true}
                        name="add"
                        borderRadius="50%"
                        width={addCompanyIcon}
                        height={addCompanyIcon}
                        iconImageWrapperClassName="text-center"
                        className="p-4"
                        iconBackgroundColor="#898989"
                        fillColor="#fff"
                        labelClassName="colorPrimary companyName cursor-pointer mobileFont"
                        title={t("Add company")}
                        titleImageWrapperClassName="text-center mt-3"
                        subIconImageWrapperClassName="text-center mt-2"
                        subIconname="Settings"
                        handleCompanyClick={() => {
                          handleCompanyClick(null);
                        }}
                        cusror="pointer"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddCompanyModal
            show={showModal}
            handleClose={handleCloseModal}
            pcValue={pcValue}
            handlePcChange={handlePcChange}
            companyid={companyData?.companyid}
            countries={companyData?.countries}
            pc={companyData?.pc}
            iban={companyData?.iban}
            timezones={companyData?.timezones}
          />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default WelcomeDashboard;
