import { t } from "../CentralDataMangement/translation/Translation";
import { CooperationAgreementState, EmailField } from "./Interfaces";

export const initialState: CooperationAgreementState = {
  formData: {
    MainDetail: {
      id: 0,
      employer_id: 0,
      company_id: 0,
      sales_agent_id: 0,
      request_at: "",
      sales_signed_at: "",
      employer_signed_at: "",
      assigned_type: 0,
      start_date: "",
      end_date: null,
      status: 0,
      bright_Id: 0,
      created_by: null,
      updated_by: 0,
      is_deleted: 0,
      deleted_by: null,
      employer_req_at: null,
      is_blocked: false,
      blocked_reason: null,
    },
    FirstStep: {
      id: 0,
      consultant_id: 0,
      office_id: 0,
      coop_agg_id: 0,
      add_project: false,
      wp_fische_language: 0,
      auto_sign_wp_fische: 0,
      updated_by: 0,
      who_will_sign_the_wpf: null,
    },
    CompanyConfig: {
      id: 0,
      coop_agg_id: 0,
      vat_number: "",
      official_company_name: "",
      company_name: "",
      company_rsz_number: "",
      company_type: 0,
      client_number: "",
      browsers: 0,
      legal_address_id: 0,
      legal_address: {
        id: 0,
        street: "",
        number: "",
        box: "",
        city: "",
        country: 0,
        postal_code: "",
        latitude: "",
        longitude: "",
      },
      website: "",
      phone_number: "",
      fax_number: "",
      company_language: 0,
      labour_regulation: 0,
      legal_entity_mail: "",
    },
    CompanyContactPersonDetails: [],
    CooperationAgreementOnlineDetails: {
      id: 0,
      coop_agg_id: 0,
      online_prestaties_mail: "",
      prestatieblad_mail: "",
      digital_mail: "",
      digital_contract_mail: "",
      invoice_reminder_mail: "",
      online_prestaties: false,
      digital_contract: false,
      online_encodage: false,
      prestatieblad: false,
    },
    CooperationAgreementInvoiceConfig: {
      id: 0,
      coop_agg_id: 0,
      vat_rate: "",
      vat_applicable: false,
      domicilry_payment: false,
      private_customer: false,
      digital_invoice: false,
      invoice_frequency: 0,
      invoice_mail: "",
      payment_condition: null,
      invoice_correction_type: 0,
      invoice_per_employee: false,
      invoice_per_location: false,
      invoice_per_worker_servant: false,
      total_per_cc: false,
      actual_work_hours: false,
      actual_work_hours_per_cc: false,
      medical_health_check: false,
      interim_person_details: false,
      contact_discount: false,
      customer_code: "",
    },
  },
  errors: {},
};

// OnlineDetails.tsx
export const emailFields: EmailField[] = [
  {
    label: t("Email address legal entity"),
    name: "digital_mail",
    isDisabled: true,
  },
  {
    label: t("Email address online performance"),
    name: "online_prestaties_mail",
  },
  {
    label: t("Email address performance sheet"),
    name: "prestatieblad_mail",
  },
  {
    label: t("Email address digital contracts"),
    name: "digital_contract_mail",
  },
  {
    label: t("Email address invoicing reminders 'rappels'"),
    name: "invoice_reminder_mail",
  },
];

export const radioFields = [
  {
    title: t("Digital contract"),
    name: "digital_contract",
    ids: ["digitalYes", "digitalNo"],
  },
  {
    title: t("Online performance"),
    name: "online_prestaties",
    ids: ["onlineYes", "onlineNo"],
  },
  {
    title: t("New online encodage"),
    name: "online_encodage",
    ids: ["newEncodageYes", "newEncodageNo"],
  },
  {
    title: t("Performance sheet"),
    name: "prestatieblad",
    ids: ["performanceYes", "performanceNo"],
  },
];
