import "./css/login.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import Icon from "../../../../Icon";
import LoadImage from "../../../../static/images/LoginImage.png";
import InputTextFieldWithLabel from "../../../common/molecules/InputTextFieldWithLabel";
import Button from "../../../common/atoms/Button";
import LinkCustom from "../../../common/atoms/Link";
// import { t } from "../../../common/atoms/translation";
import IconWithTitle from "../../../common/molecules/IconWithTitle";
import { useNavigate } from "react-router-dom";
import AddCompanyModal from "../../../Dashboard/AddCompany";
import * as ROUTE from "../../../../routes/RouteConstants";
// import {t , translate } from "../CentralDataMangement/translation/Translation";
import { getLanguage, setDefaultLanguage, setTranslations, t, translate } from "../../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";
import { GET_COUNTRIES, LOGIN, PC_LIST, REGISTER_COUNTRY_LIST, REGISTER_SETTINGS_LIST, SETTINGS_LIST, TRANSLATION_BY_LANGUAGE } from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { useDispatch } from "react-redux";
import { addUserDetails, selectAuth } from "../../../../features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { DropdownOptions } from "../../../Dashboard/services/CompanyInterface";
import { Settings } from "http2";
import { setSettings } from "../../../../features/settings/settingSlice";
import { ABSOLUTE_CORE_APP, CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";

type OptionType = { label: string; value: string };

interface LoginState {
	email: string;
	password: string;
}


interface Option {
	value: number;
	label: string;
	bright_id: number;
}

const Login: React.FC = () => {
	const navigator = useNavigate();
	const dispatch = useDispatch();
	const authData = useSelector(selectAuth);
	const [state, setState] = useState<LoginState>({
		email: '',
		password: ''
	});
	const [companyModal, setCompanyModal] = useState(false);
	const [pcValue, setPcValue] = useState<OptionType[] | null>(null);

	const handlePcChange = (selectedOption: OptionType[] | null) => {
		setPcValue(selectedOption);
	};
	const [companyData, setCompanyData] = useState<{
		countries: DropdownOptions[] | undefined;
		iban: DropdownOptions[] | undefined;
		pc: DropdownOptions[] | undefined;
		timezones: DropdownOptions[] | undefined;
	}>({
		countries: [],
		iban: [],
		pc: [],
		timezones: [],
	});

	const user = useSelector((state: any) => state?.auth);

	const fetchSettings = async (user: any, dispatch: any) => {
		try {
			const url = user.token ? SETTINGS_LIST : REGISTER_SETTINGS_LIST;
			const result = await ApiCall.getService(url, 'GET');
			dispatch(
				setSettings({
					dependent_spouse: result?.data?.dependent_spouse || [],
					driving_license: result?.data?.driving_license || [],
					employee_types: result?.data?.employee_types || [],
					gender: result?.data?.gender || [],
					marital_status: result?.data?.marital_status || [],
					transportations: result?.data?.transportations || [],
					languages: result?.data?.languages || [],
					initial: result?.data?.initial || [],
					education: result?.data?.education || [],
					iban_countries: result?.data?.iban_countries || [],
					employee_type_groups: result?.data?.employee_type_groups || [],
					timezones: result?.data?.timezones || [],
					browsers: result?.data?.browsers || [],
				})
			);
		} catch (error) {
			console.error('Error fetching settings:', error);
		}
	};

	useEffect(() => {
		const showPasswordCheckbox = document?.getElementById(
			"showPassword"
		) as HTMLInputElement;
		const passwordInput = document?.getElementById(
			"password"
		) as HTMLInputElement;

		if (showPasswordCheckbox && passwordInput) {
			// Define the event handler function as an arrow function
			const togglePasswordVisibility = () => {
				if (showPasswordCheckbox?.checked) {
					passwordInput.type = "text";
				} else {
					passwordInput.type = "password";
				}
			};

			// Add the event listener
			showPasswordCheckbox?.addEventListener("click", togglePasswordVisibility);

			// Clean up the event listener on component unmount
			return () => {
				showPasswordCheckbox?.removeEventListener(
					"click",
					togglePasswordVisibility
				);
			};
		}
	}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
	const items = [
		{ name: "Time", title: "SAVE TIME" },
		{ name: "pathway", title: "FLEXIBLE" },
		{ name: "protection", title: "WEEKLY PAY" },
		{ name: "timeSlot", title: "24/7 CONTRACTING" },
		{ name: "security", title: "SAFETY" },
		{ name: "shakeHands", title: "SECURITY" },
		{ name: "Headset", title: "ANSWERS TO ALL YOUR QUESTIONS" },
		{ name: "editAgreement", title: "CONTRACTING TOOL" },
	];

	const validate = () => {
		return state?.email !== '' && state?.password !== ''
	}

	const handleLoginClick = async () => {
		let isValidated = validate();

		if (isValidated) {
			// Set the email to lowercase before proceeding
			const lowercaseEmail = state.email?.toLowerCase();
			const updatedState = {
				...state,
				email: lowercaseEmail
			};
			const response = await ApiCall?.service(
				LOGIN,
				"POST",
				updatedState,
				false,
				CENTRAL_DATA_MANAGEMENT_MICROSERVICE
			);

			if (response.status === 'success') {

				setDefaultLanguage('nl')
				const translations = await ApiCall.service(
					// `translations-by-language`,
					TRANSLATION_BY_LANGUAGE,
					"POST",
					{
						app_name: ABSOLUTE_CORE_APP,
						lang: 'nl',
					},
					false,
					CENTRAL_DATA_MANAGEMENT_MICROSERVICE
				);

				setTranslations(translations?.translations);

				let data = response?.data;
				dispatch(addUserDetails({
					token: data?.access_token,
					userId: data?.user?.id,
					email: data?.user?.email,
					role: data?.user?.roles,
					name: `${data?.user?.first_name} ${data?.user?.last_name}`,
					first_name: data?.user?.first_name,
					last_name: data?.user?.last_name,
					phone_number: data?.user?.phone_number,
					profilePicPath: data?.user?.profile_url,
					companies: data?.user?.companies,
					currentCompany: data?.user?.companies?.[0],
					primaryOfficeID: data?.user?.primaryOfficeID
				}));
				fetchSettings(user, dispatch);
				navigator('/welcome-dashboard');
				if (Array.isArray(data?.user?.roles) && data.user.roles.includes('sales_agent')) {
					navigator('/dashboard/follow-up');
				}
				if (authData.role[0] !== "sales_agent") {
					navigator('/dashboard/follow-up');
				}
				CustomNotify({
					type: 'success',
					message: t(response?.message)
				})
			} else {
				CustomNotify({
					type: 'error',
					message: t(response?.message)
				})
			}
		} else {
			CustomNotify({
				type: 'error',
				message: t('The e-mail and password fields are required')
			})
		}
	}

	const fetchCountryOptions = async () => {
		let url;
		if (user.token) {
			url = GET_COUNTRIES;
		} else {
			url = REGISTER_COUNTRY_LIST;
		}
		try {
			const result = await ApiCall?.getService(url, "GET");
			setCompanyData((prev) => ({
				...prev,
				countries: result?.data || [],
			}));
		} catch (error) {
			console.error("Error fetching country data:", error);
		}
	};

	const fetchPcOptions = async () => {
		try {
			const result = await ApiCall?.getService(PC_LIST, "GET");
			const pcData = result?.data?.map((pc: any) => ({
				value: pc?.value,
				label: pc?.label,
			}));
			setCompanyData((prev) => ({
				...prev,
				pc: pcData,
			}));
		} catch (error) {
			console.error("Error fetching PC data:", error);
		}
	};

	const fetchIbanCountries = async () => {
		try {
			let url;
			if (user.token) {
				url = SETTINGS_LIST;
			} else {
				url = REGISTER_SETTINGS_LIST;
			}
			const result = await ApiCall.getService(url, "GET");
			const ibanData = result?.data?.iban_countries?.map((iban: any) => ({
				value: iban?.value,
				label: iban?.label,
			}));
			const timezoneData = result?.data?.timezones?.map((timezone: any) => ({
				value: timezone?.value,
				label: timezone?.label,
			}));
			setCompanyData((prev) => ({
				...prev,
				iban: ibanData,
				timezones: timezoneData,
			}));
		} catch (error) {
			console.error("Error fetching IBAN countries:", error);
		}
	};

	const handleAddcompany = () => {
		fetchCountryOptions();
		fetchPcOptions();
		fetchIbanCountries();
		setCompanyModal(!companyModal);
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e?.target;

		setState((prevState: LoginState) => ({
			...prevState,
			[name]: value
		}));
	}

	return (
		<div className="container-fluid">
			<div className="loginWrapper">
				<div className="row gx-5">
					<div className="col-4 d-none d-lg-block">
						<div className="loginLeftSection text-center h-100 position-relative">
							<div className="loginLeftSectionContent text-white">
								{t("Completely")}
								<span className="colorYellow ms-1">{t("digital")},</span>
								<br />
								{t("but with a")}
								<br />
								{t("smile")}!
							</div>
							<div className="loginImageWrapper">
								<img src={LoadImage} alt={t("login")}  />
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-10 mx-auto loginMiddleSection">
						<div className="card border-0 loginCard h-100 rounded-0">
							<div className="loginCardPadding">
								<div className="text-center">
									<Icon
										name="Logo"
										width="15vw"
										height="3vw"
										className="img-fluid mx-auto loginLogo"
										isIcon={true}
									/>
								</div>
								<div className="loginMiddleSectionContent text-center">
									<div className="colorSkyBlue loginInText mobileFont">
										{t("Login to AbsoluteCore")}
									</div>
									<div className="row">
										<InputTextFieldWithLabel
											placeholder={t("Email address")}
											type="email"
											value={state?.email}
											name={"email"}
											id="emailId"
											className="mb-4 mb-lg-3 rounded-0"
											handleChange={handleChange}
										/>
										<InputTextFieldWithLabel
											placeholder={t("Password")}
											type="password"
											value={state.password}
											name={"password"}
											id="password"
											className="mt-lg-1 mt-2 mb-3 mb-lg-2 rounded-0"
											handleChange={handleChange}
										/>
										<div className="text-start d-flex align-items-center mt-1">
											<input
												type="checkbox"
												id="showPassword"
												className="me-2 mt-0 form-check-input shadow-none"
											/>
											<label
												htmlFor="showPassword"
												className="showPasswordText"
											>
												{t("Show password")}
											</label>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<Button
												type="submit"
												title={t("Login")}
												className="signInBtn w-100 mobileFont"
												handleClick={handleLoginClick}
											/>
										</div>
										<div className="col-12 mt-lg-1 mt-3 forgotLinkWrapper">
											<LinkCustom
												href="/password/forgot"
												className="linkColor mobileFon"
												title={t("Forgot password") + "?"}
											/>
										</div>
										<div className="col-12">
											<div className="newAtAbsoluteYou colorSkyBlue mb-lg-3 mb-5 mobileFont">
												{t("New at AbsoluteYOU")}
											</div>
											<LinkCustom
												href={`${ROUTE.EMPLOYEE_REGISTRATION2}?type=login`}
												title={t("Register as an employee with absoluteYOU")}
												className="linkColor registerLoginLink mobileFont"
											/>
										</div>
									</div>
								</div>
							</div>
							<hr
								style={{ margin: "0px", borderTop: "0.14vw solid #898989" }}
							/>
							<div className="loginCardPaddingSecond text-center">
								<div className="row">
									<div className="col-12">
										<LinkCustom
											// href="/a"
											onClick={handleAddcompany}
											title={t("Register as an employer with absoluteYOU")}
											className="linkColor registerLoginLink mobileFont"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-4 d-none d-lg-block">
						<div className="loginRightSection h-100">
							<div className="row">
								{items.map((item, index) => (
									<div className="col-6 text-center" key={index}>
										<IconWithTitle
											name={item?.name}
											isIcon={true}
											isTitle={true}
											className="loginImageRightSection"
											title={t(`${item?.title}`)}
											borderRadius={"10vw"}
											labelClassName="text-white loginRightSectionTitle"
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<AddCompanyModal
					show={companyModal}
					handleClose={handleAddcompany}
					pcValue={pcValue}
					handlePcChange={handlePcChange}
					countries={companyData?.countries}
					pc={companyData?.pc}
					iban={companyData?.iban}
					timezones={companyData?.timezones}
				/>
			</div>
		</div >
	);
};

export default translate(Login);
