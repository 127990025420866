import { phoneRegex, validateAddress, validateEmail, validateHouseNumber, validateNumberFields, validatePhoneNumber, validateRadioField, validateStreet, validateStringFields } from "../../services/ValidationService";
import { t } from "../CentralDataMangement/translation/Translation";
import { emailFields, radioFields } from "./State";

// Utility to get nested values from an object with a fallback value
const getNestedValue = (obj: any, path: string, fallback: any = "") => {
    const keys = path.split(".");
    let value = obj;

    for (let key of keys) {
        if (value && value.hasOwnProperty(key)) {
            value = value[key];
        } else {
            return fallback;
        }
    }

    return value;
};

// Helper to set nested errors in the correct path
const setNestedError = (errors: any, path: string, message: string) => {
    const keys = path.split(".");
    let errorObj = errors;

    keys.forEach((key, index) => {
        if (!errorObj[key]) {
            errorObj[key] = index === keys.length - 1 ? message : {};
        }
        errorObj = errorObj[key];
    });
};


// Centralized validation function that utilizes the correct validator for each field
const validateDataType = (field: any, value: string) => {
    if (field.type === 'email') {
        return validateEmail(value);
    }

    if (field.type === 'phone') {
        return validatePhoneNumber(value);
    }

    if (field.type === 'houseNumber') {
        return validateHouseNumber(value);
    }

    if (field.type === 'street') {
        return validateStreet(value);
    }

    return null;
};

// Updated validation function for an entire section of the form
export const validateSection = (
    section: string,
    sectionData: any,
    inputFields: Array<any>,
    nonMandatoryFields: Array<string>
) => {
    let errors: { [key: string]: any } = {};

    // Loop over all the input fields to validate them
    inputFields.forEach((field) => {
        const fieldName = field.name;
        const fieldValue = getNestedValue(sectionData, fieldName);

        // Check if the field is mandatory and whether it has been filled
        if (!nonMandatoryFields.includes(fieldName) && field.isMandatory) {
            if (!fieldValue || fieldValue.trim() === "") {
                setNestedError(errors, `${section}.${fieldName}`, "This field is required.");
                return;
            }
        }

        // Validate the field data type if it's filled
        if (fieldValue && fieldValue.trim() !== "") {
            const typeError = validateDataType(field, fieldValue.trim());
            if (typeError) {
                setNestedError(errors, `${section}.${fieldName}`, typeError);
            }
        }
    });

    return errors;
};

// OnlineDetails Tab validation
export const validateOnlineDetails =(agreementData:any)=> {
    const newErrors: Record<string, string> = {};
  
    // Validate email fields
    emailFields.forEach((field) => {
        const value = agreementData?.[field.name] || "";
        const error = validateEmail(value);
        if (error) {
            newErrors[field.name] = error;
        }
    });
  
    // Validate radio fields
    radioFields.forEach((field) => {
        const value = agreementData?.[field.name];
        if (value === undefined || value === null) {
            newErrors[field.name] = t("This field is required.");
        }
    });
    return newErrors;   // It will returns errors object
}

// Invoicing Tab validation
export const validateForm = (state: any): Record<string, string> => {
    const errors: Record<string, string> = {};
  
    // Mandatory string fields
    const STRING_FIELDS = [
      "invoice_person_first_name",
      "invoice_person_last_name",
      "invoice_mail",
      "digital_invoice_mail",
    ];
  
    // Mandatory number fields
    const NUMBER_FIELDS = [
      "vat_rate",
    ];
  
    // Address-specific validation
    const REQUIRED_ADDRESS_FIELDS = ["street", "number", "city", "postal_code", "country"];
    validateAddress(errors, state?.address || {}, "address", REQUIRED_ADDRESS_FIELDS);
  
    // Validate string fields
    validateStringFields(state, errors, STRING_FIELDS);
  
    // Validate number fields
    validateNumberFields(state, errors, NUMBER_FIELDS);
  
    // Validate email fields
    ["invoice_mail", "digital_invoice_mail"].forEach((field) => {
      const error = validateEmail(state[field]);
      if (error) errors[field] = error;
    });
  
    // Validate phone number
    if (state.phone_number) {
      const phoneError = phoneRegex(state.phone_number);
      if (phoneError) errors["phone_number"] = phoneError;
    } else {
      errors["phone_number"] = "This field is required.";
    }
  
    // Validate radio fields
    const RADIO_FIELDS = [
      "vat_applicable",
      "domicilry_payment",
      "private_customer",
      "digital_invoice",
      "invoice_frequency",
      "invoice_per_location",
      "total_per_cc",
      "invoice_per_worker_servant",
      "actual_work_hours_per_cc",
      "invoice_per_employee",
      "medical_health_check",
      "interim_person_details",
      "contact_discount",
      "actual_work_hours",
    ];
    RADIO_FIELDS.forEach((field) => {
      const error = validateRadioField(state[field]);
      if (error) errors[field] = error;
    });
  
    return errors;
  };
  