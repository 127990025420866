import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/Constants";
import Header from "../common/layout/Header";
import { Tab, Tabs } from "react-bootstrap";
import "./css/Agreement.css";
import AbsoluteyouAgent from "./AbsoluteyouAgent";
import CompanyInformation from "./CompanyInformation";
import ContactPerson from "./ContactPerson";
import OnlineDetails from "./OnlineDetails";
import SalaryBenifits from "./SalaryBenefits";
import Invoicing from "./Invoicing";
import { GET_AGREEMENT_DETAILS, REGISTER_COUNTRY_LIST } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { CooperationAgreementState, Formdata } from "./Interfaces";
import { initialState } from "./State";

// Data array for tabs
const tabData = [
    {
        eventKey: "absoluteyouAgent",
        step: "Step 1",
        title: "AbsoluteYou Agent",
        component: AbsoluteyouAgent,
    },
    {
        eventKey: "CompanyConfig",
        step: "Step 2",
        title: "Company Information",
        component: CompanyInformation,
    },
    {
        eventKey: "contactPersons",
        step: "Step 3",
        title: "Contact Persons",
        component: ContactPerson,
    },
    {
        eventKey: "CooperationAgreementOnlineDetails",
        step: "Step 4",
        title: "Online Details",
        component: OnlineDetails,
    },
    {
        eventKey: "salaryBenefits",
        step: "Step 5",
        title: "Salary Benefits",
        component: SalaryBenifits,
    },
    {
        eventKey: "CooperationAgreementInvoiceConfig",
        step: "Step 6",
        title: "Invoicing",
        component: Invoicing,
    },
];

interface Option {
    value?: number | string | null;
    label?: string;
}


const AgreementForm: React.FC = () => {
    const [formState, setFormState] = useState<CooperationAgreementState>(initialState);
    const [countries, setCountries] = useState<Option[]>([]);
    const [userDetails, setUserDetails] = useState<any>({});
    const [settings, setSettings] = useState<any>({});
    const [activeTab, setActiveTab] = useState("absoluteyouAgent");
    const handleNext = () => {
        const currentIndex = tabData.findIndex((tab) => tab.eventKey === activeTab);
        if (currentIndex === tabData.length - 1) {
            console.log("Already on the last tab");
            handleSubmit();
            return;
        }
        setActiveTab(tabData[currentIndex + 1].eventKey);
    };
    

    const handlePrevious = () => {
        const currentIndex = tabData.findIndex((tab) => tab.eventKey === activeTab);
        if (currentIndex > 0) {
            setActiveTab(tabData[currentIndex - 1].eventKey);
        }
    };

    const handleSubmit = () => {

        // Check errors and update form state

        // Logic to submit the form
        console.log("Form submitted");
      };

    const handleRadioChange = (name: string, value: boolean, section: keyof Formdata) => {
        console.log("handleRadioChange", name, value, section);
            setFormState((prevState) => {
            if (!prevState) return prevState;

            return {
            ...prevState,
            formData: {
                ...prevState.formData,
                [section]: {
                ...prevState.formData?.[section],
                [name]: value,
                },
            },
            };
        });
    };
        
    // Fetch the agreement details from the API
    const fetchAgreement = async () => {
        const url = GET_AGREEMENT_DETAILS;
        const obj = {
            company_id: 2587,
        };
        try {
            const result = await ApiCall?.service(url, "POST", obj, true, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
            setFormState((prevState: CooperationAgreementState) => ({
                ...prevState,
                formData: result,
                errors: {},
            }));
        } catch (error) {
            console.error("Error fetching agreement data:", error);
        }
    };

    const fetchCountryOptions = async () => {
        const url = REGISTER_COUNTRY_LIST;
        try {
            const result = await ApiCall?.getService(url, "GET");
            setCountries((prev) => ({
                ...prev,
                countries: result?.data || [],
            }));
        } catch (error) {
            console.error("Error fetching country data:", error);
        }
    };

    useEffect(() => {
        fetchAgreement();
        fetchCountryOptions();
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option | any,
        section: keyof Formdata
    ) => {
        const { name, value } = e.target;
        if (formState?.formData) {
            setFormState((prevState: CooperationAgreementState) => {
                if (prevState) {
                    if (name.includes(".")) {
                        const [parentKey, childKey] = name.split(".");
                        console.log(parentKey, childKey);
                        return {
                            ...prevState,
                            formData: {
                                ...prevState?.formData,
                                [section]: {
                                    ...prevState?.formData[section],
                                    [parentKey]: {
                                        ...(prevState.formData[section] as Record<string, any>)[parentKey],
                                        [childKey]: value,
                                    },
                                },
                            }
                        };
                    }
                    // Handle simple fields (non-nested)
                    return {
                        ...prevState,
                        formData: {
                            ...prevState?.formData,
                            [section]: {
                                ...prevState?.formData?.[section],
                                [name]: value,
                            },
                        }
                    };
                }
                return prevState;
            });
            }
    };

    const handleSelectChange = (
        selectedOption: Option | null,
        fieldPath: string,
        section: keyof Formdata
    ) => {
        const value = selectedOption ? selectedOption.value : null;
        const pathParts = fieldPath.split(".");


        if (formState?.formData) {
            setFormState((prevState: CooperationAgreementState) => {
                if (prevState) {
                    // For nested fields
                    if (pathParts.length > 1) {
                        const [parentKey, childKey] = pathParts;
                        return {
                            ...prevState,
                            formData: {
                                ...prevState?.formData,
                                [section]: {
                                    ...prevState?.formData?.[section],
                                    [parentKey]: {
                                        ...(prevState?.formData?.[section] as any)[parentKey],
                                        [childKey]: value,
                                    },
                                },
                            }
                        };
                    }
                    // For non-nested fields
                    return {
                        ...prevState,
                        formData: {
                            ...prevState?.formData,
                            [section]: {
                                ...prevState?.formData?.[section],
                                [fieldPath]: value,
                            },
                        }
                    };
                }
                return prevState;
            });
        }
    };

    // Helper function to return dynamic props based on the tab key
    const getTabProps = (tabKey: string) => {
        switch (tabKey) {
            case "CompanyConfig":
                return { countries };
            case "CooperationAgreementOnlineDetails":
                return { handleRadioChange: handleRadioChange };
            case "CooperationAgreementInvoiceConfig":
                return { handleRadioChange: handleRadioChange, countries };
            default:
                return {};
        }
    };

    const updateErrors = (section: string, errors: any) => {
        setFormState((prevState: CooperationAgreementState) => ({
            ...prevState,
            errors:{
                ...prevState.errors,
                [section]: errors
            },
        }));
    }

    return (
        <div className="cooperationAgreementWrapper">
            <Header
                headerName={t("Edit cooperation agreement")}
                headerClassName="myAccountCardTitle"
            />
            <Tabs
                activeKey={activeTab}
                onSelect={(eventKey) => {
                    if (eventKey) setActiveTab(eventKey); // Ensure eventKey is not null
                }}                // defaultActiveKey="absoluteyouAgent"
                transition={false}
                id="fill-tab-example"
                className="cooperationAgreementTabs border-0 p-3 bg-white"
                fill
            >
                {tabData.map((tab, index) => {
                    const tabProps = getTabProps(tab.eventKey);
                    return (
                        <Tab
                            className="p-3 bg-white"
                            key={index}
                            eventKey={tab.eventKey}
                            style={{ minHeight: "calc(100vh - 16vw)" }}
                            title={
                                <>
                                    <div className="mb-2">{t(tab.step)}</div>
                                    {t(tab.title)}
                                </>
                            }
                        >
                            {formState?.formData && (
                                <tab.component
                                    agreementData={formState?.formData?.[tab.eventKey as keyof Formdata]}
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    errors={formState?.errors?.[tab.eventKey as keyof Formdata]}
                                    updateErrors={updateErrors}
                                    section={tab.eventKey as keyof Formdata}
                                    handleNext={handleNext}
                                    handlePrevious={handlePrevious}
                                    {...tabProps}
                                />
                            )}
                        </Tab>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default AgreementForm;
